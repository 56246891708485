<template>
    <div>
      <router-view></router-view>
    </div>
  
</template>

<script>
export default {
  data: () => ({
    drawer: true,
    mini: true,
    items: [
      {
        title: "Applicants",
        icon: "mdi-account-group-outline",
        link: "NewApplicants"
      }
    ]
  })
};
</script>
